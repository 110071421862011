<template>
  <div>
    <ingredient-list-add-new
      :is-add-new-ingredient-sidebar-active.sync="
        isAddNewIngredientSidebarActive
      "
      @refetch-data="fetchIngredients"
    />
    <ingredient-list-edit
      :key="selectedIngredient.id"
      :is-edit-ingredient-sidebar-active.sync="isEditIngredientSidebarActive"
      :ingredient.sync="selectedIngredient"
      @refetch-data="fetchIngredients"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("eachPage") }}</label>
            <v-select
              v-model="perPage"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> {{ $t("piece") }} </label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('search')"
              />
              <b-button
                variant="primary"
                @click="
                  isAddNewIngredientSidebarActive =
                    !isAddNewIngredientSidebarActive
                "
              >
                <span class="text-nowrap">{{ $t("ingredientAdd") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refIngredientListTable"
        class="position-relative"
        :items="filteredIngredients"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page.sync="currentPage"
      >
        <!-- Column: Ingredient -->
        <template #cell(ingredientname)="data">
          <b-link @click="openEditSideBar(data)">
            <span class="align-middle ml-50 text-secondary">
              {{ data.item.ingredientname }}
            </span>
          </b-link>
        </template>
        <template #cell(cinsname)="data">
          {{ data.item.cinsname == "Yoğun Yem" ? $t("grain") : $t("roughage") }}
        </template>
        <template #cell(delete)="data">
          <b-link
            @click="
              deleteIngredient({
                plantid: $route.params.id,
                ingredientid: data.item.id,
              })
            "
          >
            <div class="d-flex">
              <feather-icon icon="TrashIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("delete")
              }}</small>
            </div>
          </b-link>
        </template>

        <template #cell(edit)="data">
          <b-link @click="openEditSideBar(data)">
            <div class="d-flex">
              <feather-icon icon="EditIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("edit")
              }}</small>
            </div>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              <span class="text-muted"
                >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
                {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
                {{ $t("entries") }} </span
              ></span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filteredIngredients.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useIngredientList from "./useIngredientList";
import IngredientListAddNew from "./IngredientListAddNew.vue";
import IngredientListEdit from "./IngredientListEdit.vue";

import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import VueI18n from "@/libs/i18n";

export default {
  components: {
    IngredientListAddNew,
    IngredientListEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    vSelect,
  },
  data() {
    return {};
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "ingredients") {
      to.meta.breadcrumb = [];

      to.meta.breadcrumb.push({
        text: VueI18n.t("ingredients"),
        active: true,
      });
    }
    next();
  },
  setup({ emit }) {
    const isAddNewIngredientSidebarActive = ref(false);
    const isEditIngredientSidebarActive = ref(false);
    const selectedIngredient = ref({});
    const ingredientData = getUserData();
    const openEditSideBar = (item) => {
      selectedIngredient.value = item;
      isEditIngredientSidebarActive.value =
        !isEditIngredientSidebarActive.value;
    };
    const {
      fetchIngredients,
      tableColumns,
      perPage,
      currentPage,
      totalIngredients,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refIngredientListTable,
      refetchData,
      allIngredients,
      filteredIngredients,

      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteIngredient,
    } = useIngredientList();
    fetchIngredients();
    return {
      // Sidebar
      isAddNewIngredientSidebarActive,
      isEditIngredientSidebarActive,
      openEditSideBar,

      selectedIngredient,

      fetchIngredients,
      tableColumns,
      perPage,
      currentPage,
      totalIngredients,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refIngredientListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI
      allIngredients,
      filteredIngredients,
      // Extra Filters
      typeFilter,
      genderFilter,
      deleteIngredient,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
